.business-card {

  i.card-icon {
    margin-right: 10px;
  }

  color: #fff;
  font-family: "Roboto", serif;
  width: 100%;
  padding-bottom: 40px;

  .header {
    overflow: hidden;

    .background {
      @media (max-width: 576px) {
        img {
          margin-left: -100px;
          height: 150px;
        }
      }
    }

    .photo {
      margin-top: -100px;
      margin-left: 20px;

      img {
        border-radius: 100px;
        border: 6px solid #37517e;
      }
    }

    @media (max-width: 576px) {
      .photo {
        margin-top: -75px;
  
        img {
          border-radius: 75px;
          width: 150px;
        }
      }
    }
  }

  .name {
    font-size: 30px;
    font-weight: bold;

    margin-left: 20px;
    margin-top: 20px;
  }

  .details {

    margin-left: 30px;
    margin-top: 15px;

    & > div {
      margin-top: 10px;
    }

    a {
      color: #fff;

      &:hover {
        color: #47b2e4;
      }
    }
  }

  .vcf {
    text-align: right;
    padding-right: 30px;
    margin-top: 20px;
    max-width: 1024px;
  }
}
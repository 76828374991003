.component-services {
  .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;

    &:hover {
      transform: translateY(-10px);

      h4 {
        color: #47b2e4;
      }
    }

    .icon {
      margin-bottom: 10px;

      i {
        color: #47b2e4;
        font-size: 36px;
        transition: 0.3s;
      }
    }

    h4 {
      font-weight: 500;
      margin-bottom: 15px;
      font-size: 24px;
      color: #37517e;
      transition: ease-in-out 0.3s;
    }

    p {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
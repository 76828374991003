header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;

  &.header-scrolled {
    background: rgba(40, 58, 90, 0.9);
  }

  .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;

    a {
      color: #fff;
    }
  }
}
section.component-about {
  .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .content ul {
    list-style: none;
    padding: 0;
  }
  
  .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .content ul li+li {
    margin-top: 10px;
  }
  
  .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #47b2e4;
    line-height: 1;
  }

  .content li span {
    color: #47b2e4;
    font-weight: 600;
  }
  
  .content p:last-child {
    margin-bottom: 0;
  }
}

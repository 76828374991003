section.component-tech {
  .content {
    padding: 60px 100px 0 100px;
  }
  
  .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: #37517e;
  }
  
  .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .content p {
    font-size: 15px;
    color: #848484;
  }
  
  .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .accordion {
    p {
      padding: 0 32px 0 32px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 32px 0 32px;

      li {
        position: relative;
        padding-left: 28px;
      }

      i {
        position: absolute;
        color: #5ac59b;
        top: 0;
        left: 0;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 992px) {
    .img {
      min-height: 400px;
    }
  
    .content {
      padding-top: 30px;
    }
  }

  @media (max-width: 575px) {
    .img {
      min-height: 200px;
    }
  }
}

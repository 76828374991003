section.component-clients {
  padding: 12px 0;
  text-align: center;

  img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
    filter: grayscale(100);
  }

  img:hover {
    filter: none;
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    img {
      max-width: 40%;
    }
  }
}
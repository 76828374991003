.component-contact {
  .info {
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);

    i {
      font-size: 20px;
      color: #47b2e4;
      float: left;
      width: 44px;
      height: 44px;
      background: #e7f5fb;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
    }

    h4 {
      padding: 0 0 0 60px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      color: #37517e;
    }

    .intro {
      margin-bottom: 40px;

      p:first-child {
        color: #47b2e4;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .email p, .phone p {
      padding: 0 0 10px 60px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #6182ba;
    }

    .email p {
      padding-top: 5px;
    }

    .email:hover i,
    .address:hover i,
    .phone:hover i {
      background: #47b2e4;
      color: #fff;
    }
  }

  form.contact {
    width: 100%;
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);

    .validate {
      display: none;
      color: red;
      margin: 0 0 15px 0;
      font-weight: 400;
      font-size: 13px;
    }

    .form-group {
      padding-bottom: 8px;
      margin-bottom: 20px;

      label {
        padding-bottom: 8px;
      }

      input {
        height: 44px;
      }

      textarea {
        padding: 10px 12px;
      }

      input, textarea {
        border-radius: 0;
        box-shadow: none;
        font-size: 14px;
        border-radius: 4px;

        &:focus {
          border-color: #47b2e4;
        }
      }
    }

    button[type=submit] {
      background: #47b2e4;
      border: 0;
      padding: 12px 34px;
      color: #fff;
      border-radius: 50px;
      transition: 0.5s;

      &:hover {
        background: #209dd8;
      }
    }

    .result {
      margin-bottom: 20px;
      text-align: center;

      .success {
        color: #209dd8;
        border: 1px solid #209dd8;
        border-radius: 5px;
        padding: 15px;
      }

      .error-message {
        color: #db0125;
        border: 1px solid #db0125;
        border-radius: 5px;
        padding: 15px;
      }
    }
  }
}
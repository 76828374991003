nav.component-navbar {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  li {
    position: relative;
  }

  a, a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
  }

  a i, a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  a:hover, .active, .active:focus, li:hover>a {
    color: #47b2e4;
  }

  .cta, .cta:focus {
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    border: 2px solid #47b2e4;
    font-weight: 600;
  }

  .cta:hover, .cta:focus:hover {
    color: #fff;
    background: #31a9e1;
  }
}

nav.component-navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;

  .mobile-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }

  a, a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #37517e;
  }

  a:hover, .active, li:hover>a {
    color: #47b2e4;
  }

  .cta, .cta:focus {
    margin: 15px;
    color: #37517e;
  }
}

nav.component-navbar .mobile-toggle,
nav.component-navbar-mobile .mobile-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;

  &.bi-x {
    color: #fff;
  }
}

@media (max-width: 991px) {
  nav.component-navbar .mobile-toggle,
  nav.component-navbar-mobile .mobile-toggle {
    display: block;
  }

  nav.component-navbar ul {
    display: none;
  }
  nav.component-navbar-mobile ul {
    display: block;
  }
}
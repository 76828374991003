footer {
  font-size: 14px;
  background: #37517e;

  .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;

    .copyright {
      float: left;
    }
  }
}

@media (max-width: 768px) {
  footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  footer .copyright {
    text-align: center;
    float: none;
  }
}
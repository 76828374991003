section.component-process {

  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  padding: 120px 0;

  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  p {
    color: #fff;
  }
  
  ul {
    padding: 0;
    list-style: none;

    li p {
      font-size: 14px;
    }
  }

  span {
    color: #47b2e4;
    font-weight: bold;
  }

  .cta, .cta:focus {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta:hover, .cta:focus:hover {
    background: #47b2e4;
    border: 2px solid #47b2e4;
  }
}
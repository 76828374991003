@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;

  background-color: #37517e;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}

section {
  padding: 60px 0;
  overflow: hidden;

  background-color: #fff;

  &.section-bg {
    background-color: #f3f5fa;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;
  }
  
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
}

.accordion {

  padding: 0 100px 60px 100px;

  .accordion-item {
    margin-top: 15px;
    border: 0;
  }

  .accordion-body {
    padding: 0 20px 20px 20px;
    background: #fff;
  }

  .accordion-header {
    padding: 20px;
    padding-right: 30px;

    .accordion-button {
      display: block;
      position: relative;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 0;
      outline: none;
      cursor: pointer;

      i.icon-show, i.icon-close {
        font-size: 15px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .icon-show {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        background: #fff;
        color: #47b2e4;
        box-shadow: none;
      }

      &:after {
        display: none;
      }

      &.collapsed {
        color: #343a40;
  
        &:hover {
          color: #47b2e4;
        }
  
        .icon-show {
          display: inline-block;
        }
  
        .icon-close {
          display: none;
        }
      }
    }      
  }

  span {
    color: #47b2e4;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
}

.accordion-list {
  padding: 0 100px 60px 100px;

  ul {
    padding: 0;
    list-style: none;
  }

  li+li {
    margin-top: 15px;
  }

  li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }

  a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    cursor: pointer;
  }

  span {
    color: #47b2e4;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }

  i.icon-show, i.icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }

  p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }

  .icon-show {
    display: none;
  }

  a.collapsed {
    color: #343a40;

    &:hover {
      color: #47b2e4;
    }

    .icon-show {
      display: inline-block;
    }

    .icon-close {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .accordion {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .accordion {
    padding-bottom: 30px;
  }
}